import styled from 'styled-components'
import Dimensions from 'spartacus/styles/dimensions'
import Colors from 'spartacus/styles/colors'

export const Container = styled.footer`
  background: ${Colors.Brand.Primary4};
  padding: 8vh ${Dimensions.nColumns(1)} 4vh ${Dimensions.nColumns(1)};

  @media ${Dimensions.lg} {
    padding: 12vh ${Dimensions.nColumns(2)} 8vh ${Dimensions.nColumns(2)};
  }
`

export const Sections = styled.div`
  margin-bottom: 12vh;

  @media ${Dimensions.lg} {
    display: flex;
  }
`

export const Section = styled.div`
  margin-bottom: 6vh;

  p {
    margin-bottom: 2vh;
  }

  @media ${Dimensions.lg} {
    margin: 0 ${Dimensions.nColumns(1)} 0 0;
    width: ${Dimensions.nColumns(8)};

    &:nth-child(2) {
      width: ${Dimensions.nColumns(6)};
    }

    &:nth-child(3) {
      width: ${Dimensions.nColumns(7)};
      margin: 0;
    }

    p {
      margin-bottom: 4vh;
    }
  }
`

export const List = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`

export const ListItem = styled.li`
  display: block;
  margin-bottom: 1vh;

  @media ${Dimensions.lg} {
    margin-bottom: 2vh;
  }
`

export const LogoContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-right: ${Dimensions.nColumns(2)};

  svg {
    width: ${Dimensions.nColumns(7)};
  }

  @media ${Dimensions.lg} {
    padding: 0;

    svg {
      width: ${Dimensions.nColumns(2.5)};
    }
  }
`
