import React from 'react'
import Link from 'next/link'
import { useRouter } from 'next/router'
import Header from 'spartacus/components/Header'
import Typography from 'spartacus/components/Typography'
import DashboardPrimaryNav from 'spartacus/components/Nav/DashboardPrimaryNav'
import DashboardSecondaryNav from 'spartacus/components/Nav/DashboardSecondaryNav'
import Footer from 'spartacus/components/Footer'
import Colors from 'spartacus/styles/colors'
import * as S from './style'

export interface Props {
  children: React.ReactNode
  variant?: 'new-dashboard' | 'ancillary'
  showHeader?: boolean
  showFooter?: boolean
}

const Layout = ({
  children,
  variant,
  showHeader = true,
  showFooter = true,
}: Props): JSX.Element => {
  const router = useRouter()

  if (variant === 'new-dashboard') {
    return (
      <S.DashboardLayout>
        <S.DashboardPrimaryNav>
          <DashboardPrimaryNav />
        </S.DashboardPrimaryNav>
        <S.DashboardSecondaryNav>
          <DashboardSecondaryNav />
        </S.DashboardSecondaryNav>
        <S.DashboardContent>{children}</S.DashboardContent>
        <S.DashboardFooter>
          <S.FooterLinks>
            <Link href="/terms-and-conditions" passHref>
              <Typography element="a" kind="TSL - Legal">
                Terms &amp; Conditions
              </Typography>
            </Link>
            <Typography kind="TSL - Legal">|</Typography>
            <Link href="/privacy-policy" passHref>
              <Typography element="a" kind="TSL - Legal">
                Privacy
              </Typography>
            </Link>
          </S.FooterLinks>
          <Typography kind="TSL - Legal" color={Colors.Type.Secondary}>
            &copy; {new Date().getFullYear()} Data Fiduciary, Inc. All rights reserved.
          </Typography>
        </S.DashboardFooter>
      </S.DashboardLayout>
    )
  }

  if (variant === 'ancillary') {
    return (
      <S.Container>
        {showHeader && <Header pathName={router.pathname} />}
        <S.AncillaryContent>{children}</S.AncillaryContent>
        {showFooter && <Footer />}
      </S.Container>
    )
  }

  return (
    <S.Container>
      {showHeader && <Header pathName={router.pathname} />}
      <S.Content>{children}</S.Content>
      {showFooter && <Footer />}
    </S.Container>
  )
}
export default Layout
