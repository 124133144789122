import React, { memo } from 'react'
import Link from 'next/link'
import {
  Toolbar as MuiToolbar,
  IconButton as MuiIconButton,
  Drawer as MuiDrawer,
} from '@material-ui/core'
import { MenuRounded as MenuIcon, CloseRounded as CloseIcon } from '@material-ui/icons'
import Logo from 'spartacus/components/Header/Logo'
import Typography from 'spartacus/components/Typography'
import SessionStore from 'spartacus/stores/SessionStore'
import AnalyticsStore from 'spartacus/stores/AnalyticsStore'
import { VariantProp } from '.'
import * as S from './style'

interface Props extends VariantProp {
  sessionStore: SessionStore
  analyticsStore: AnalyticsStore
  isMobileMenuOpen: boolean
  setIsMobileMenuOpen: React.Dispatch<React.SetStateAction<boolean>>
  setDesktopMenuAnchorElement: React.Dispatch<React.SetStateAction<HTMLElement | null>>
}

const MobileNavigation = ({
  variant,
  sessionStore,
  isMobileMenuOpen,
  setIsMobileMenuOpen,
  analyticsStore,
  setDesktopMenuAnchorElement,
}: Props & VariantProp): JSX.Element => {
  const drawerClasses = S.getDrawerClasses(variant)
  const toolbarClasses = S.getToolbarClasses(variant)
  const iconButtonClasses = S.getIconButtonClasses(variant)

  return (
    <>
      <MuiIconButton
        aria-label="Main menu"
        onClick={(): void => {
          setIsMobileMenuOpen(true)
        }}
        edge="end"
      >
        <MenuIcon color="inherit" classes={iconButtonClasses} />
      </MuiIconButton>

      <MuiDrawer
        anchor="right"
        open={isMobileMenuOpen}
        onClose={(): void => {
          setIsMobileMenuOpen(false)
        }}
        classes={drawerClasses}
      >
        <MuiToolbar disableGutters classes={toolbarClasses}>
          <Logo
            user={sessionStore.user}
            setDesktopMenuAnchorElement={setDesktopMenuAnchorElement}
            variant={variant}
            analyticsStore={analyticsStore}
          />
          <S.Spacer />
          <MuiIconButton
            onClick={(): void => {
              setIsMobileMenuOpen(false)
            }}
            edge="end"
            color="inherit"
          >
            <CloseIcon />
          </MuiIconButton>
        </MuiToolbar>
        <S.MobileNavigationDrawerContent>
          {sessionStore.user ? (
            <>
              <Link href="/dashboard" passHref>
                <Typography
                  kind="TS4 - Strong"
                  element="a"
                  onClick={(): void => {
                    setIsMobileMenuOpen(false)

                    analyticsStore.event({
                      category: 'nav',
                      action: 'page',
                      label: 'dashboard',
                    })
                  }}
                >
                  Dashboard
                </Typography>
              </Link>

              {sessionStore.abilities.can('read', 'Account') && (
                <Link href="/account" passHref>
                  <Typography
                    kind="TS4 - Strong"
                    element="a"
                    onClick={(): void => {
                      setIsMobileMenuOpen(false)

                      analyticsStore.event({
                        category: 'nav',
                        action: 'page',
                        label: 'account',
                      })
                    }}
                  >
                    Account Settings
                  </Typography>
                </Link>
              )}

              <Link href="/logged-out" passHref>
                <Typography
                  kind="TS4 - Strong"
                  element="a"
                  onClick={(): void => {
                    setIsMobileMenuOpen(false)

                    analyticsStore.event({
                      category: 'account',
                      action: 'nav',
                      label: 'log out',
                    })
                  }}
                >
                  Log Out
                </Typography>
              </Link>
            </>
          ) : (
            <>
              <Link href="/offer" passHref>
                <Typography
                  kind="TS4 - Strong"
                  element="a"
                  onClick={(): void => {
                    setIsMobileMenuOpen(false)

                    analyticsStore.event({
                      category: 'nav',
                      action: 'page',
                      label: 'get started',
                    })
                  }}
                >
                  Get Started
                </Typography>
              </Link>

              <Link href="/log-in" passHref>
                <Typography
                  kind="TS4 - Strong"
                  element="a"
                  onClick={(): void => {
                    setIsMobileMenuOpen(false)

                    analyticsStore.event({
                      category: 'nav',
                      action: 'page',
                      label: 'log in',
                    })
                  }}
                >
                  Log In
                </Typography>
              </Link>
            </>
          )}

          <Link href="/our-promise" passHref>
            <Typography
              kind="TS4 - Strong"
              element="a"
              onClick={(): void => {
                setIsMobileMenuOpen(false)

                analyticsStore.event({
                  category: 'nav',
                  action: 'page',
                  label: 'our promise',
                })
              }}
            >
              Our Promise
            </Typography>
          </Link>

          <Link href="/terms-and-conditions" passHref>
            <Typography
              kind="TS4 - Strong"
              element="a"
              onClick={(): void => {
                setIsMobileMenuOpen(false)

                analyticsStore.event({
                  category: 'nav',
                  action: 'page',
                  label: 'terms',
                })
              }}
            >
              Terms
            </Typography>
          </Link>

          <Typography
            kind="TS4 - Strong"
            element="a"
            href="https://cdn.spartacus.com/white-papers/fiduciarydatabanking.3.7.2019.pdf"
            onClick={(): void => {
              setIsMobileMenuOpen(false)

              analyticsStore.event({
                category: 'nav',
                action: 'page',
                label: 'white paper',
              })
            }}
          >
            White Paper
          </Typography>
        </S.MobileNavigationDrawerContent>
      </MuiDrawer>
    </>
  )
}

export default memo(MobileNavigation)
