import { useEffect, useState } from 'react'

function useScroll(): { scrollX: number; scrollY: number } {
  const [scrollX, setScrollX] = useState<number>(0)
  const [scrollY, setScrollY] = useState<number>(0)

  function listener(): void {
    setScrollX(window.scrollX)
    setScrollY(window.scrollY)
  }

  useEffect(() => {
    window.addEventListener('scroll', listener)

    return (): void => {
      window.removeEventListener('scroll', listener)
    }
  }, [])

  return { scrollX, scrollY }
}

export default useScroll
