import React, { CSSProperties } from 'react'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { observer } from 'mobx-react'
import { Drawer, IconButton as MuiIconButton } from '@material-ui/core'
import { MenuRounded as MenuIcon, CloseRounded as CloseIcon } from '@material-ui/icons'
import useStores from 'spartacus/hooks/useStores'

import Logo from 'spartacus/components/Logo'
import Typography from 'spartacus/components/Typography'
import Colors from 'spartacus/styles/colors'
import * as S from './style'

const DashboardPrimaryNav = observer(
  (): JSX.Element => {
    const { sessionStore } = useStores()
    const router = useRouter()
    const [drawerIsVisible, setDrawerIsVisible] = React.useState(false)

    function renderLogo(style: CSSProperties = {}): JSX.Element {
      return <Logo type="lockup" color={Colors.UI.White} style={style} />
    }

    const renderDesktopNavLink = (label: string, href: string): JSX.Element => (
      <S.DesktopNavLink data-active={router.pathname === href}>
        <Link href={href} passHref>
          <Typography element="a" kind="TS1 - Strong">
            {label}
          </Typography>
        </Link>
      </S.DesktopNavLink>
    )

    const renderMobileNavLink = (label: string, href: string): JSX.Element => (
      <Link href={href} passHref>
        <Typography element="a" kind="TS4 - Strong">
          {label}
        </Typography>
      </Link>
    )

    return (
      <S.Container>
        <S.DesktopNav>
          <S.DesktopLogo>{renderLogo({ width: '100%' })}</S.DesktopLogo>
          <S.DesktopNavLinks>
            {renderDesktopNavLink('Dashboard', '/dashboard')}

            {sessionStore.abilities.can('read', 'Exposure') &&
              renderDesktopNavLink('Data Deletion', '/dashboard/scan-results')}

            {sessionStore.abilities.can('read', 'Data Breach Without Password') &&
              renderDesktopNavLink('Data Breaches', '/dashboard/data-breaches-results')}

            {sessionStore.abilities.can('read', 'Exposure') &&
              renderDesktopNavLink('Support', '/dashboard/support')}
          </S.DesktopNavLinks>
        </S.DesktopNav>
        <S.MobileNav>
          <S.MobileLeft>{renderLogo({ height: '40px' })}</S.MobileLeft>
          <S.MobileCenter />
          <S.MobileRight>
            <MuiIconButton
              onClick={(): void => {
                setDrawerIsVisible(true)
              }}
              edge="end"
              color="inherit"
            >
              <MenuIcon />
            </MuiIconButton>
          </S.MobileRight>
        </S.MobileNav>

        <Drawer
          anchor="right"
          open={drawerIsVisible}
          onClose={(): void => {
            setDrawerIsVisible(false)
          }}
        >
          <S.DrawerTop>
            {renderLogo({ height: '40px' })}
            <MuiIconButton
              onClick={(): void => {
                setDrawerIsVisible(false)
              }}
              edge="end"
              color="inherit"
            >
              <CloseIcon />
            </MuiIconButton>
          </S.DrawerTop>
          <S.DrawerContent>
            {renderMobileNavLink('Dashboard', '/dashboard')}

            {sessionStore.abilities.can('read', 'Exposure') &&
              renderMobileNavLink('Data Deletion', '/dashboard/scan-results')}

            {sessionStore.abilities.can('read', 'Data Breach Without Password') &&
              renderMobileNavLink('Data Breaches', '/dashboard/data-breaches-results')}

            {sessionStore.abilities.can('read', 'Exposure') &&
              renderMobileNavLink('Support', '/dashboard/support')}

            {sessionStore.abilities.can('read', 'Account') &&
              renderMobileNavLink('Settings', '/account')}

            {sessionStore.user && renderMobileNavLink('Log Out', 'logged-out')}
          </S.DrawerContent>
        </Drawer>
      </S.Container>
    )
  },
)

export default DashboardPrimaryNav
