import React, { memo } from 'react'
import Link from 'next/link'
import Logo from 'spartacus/components/Logo'
import Colors from 'spartacus/styles/colors'
import { User } from 'spartacus/stores/SessionStore'
import AnalyticsStore from 'spartacus/stores/AnalyticsStore'
import { VariantProp } from '.'

interface Props extends VariantProp {
  user?: User
  analyticsStore: AnalyticsStore
  setDesktopMenuAnchorElement: React.Dispatch<React.SetStateAction<HTMLElement | null>>
}

const HeaderLogo = ({
  user,
  analyticsStore,
  variant,
  setDesktopMenuAnchorElement,
}: Props): JSX.Element => (
  <Link href={user ? '/dashboard' : '/'}>
    <a
      onClick={(): void => {
        setDesktopMenuAnchorElement(null)
        analyticsStore.event({
          category: 'nav',
          action: 'page',
          label: 'logo',
        })
      }}
      aria-label="Spartacus logo"
      style={{ height: '40px' }}
    >
      <Logo
        type="lockup"
        color={variant === 'light' ? Colors.Brand.Primary1 : Colors.UI.White}
        style={{ height: '40px' }}
      />
    </a>
  </Link>
)

export default memo(HeaderLogo)
