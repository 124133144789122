import * as React from 'react'
import Colors from 'spartacus/styles/colors'

interface Props {
  color?:
    | typeof Colors.UI.White
    | typeof Colors.Brand.Primary1
    | typeof Colors.Brand.Primary3
    | typeof Colors.Brand.Primary5
  style?: React.CSSProperties
  type?: 'lockup' | 'mark'
}

const Lockup = ({ color = Colors.UI.White, style }: Props): JSX.Element => (
  <svg viewBox="0 0 162 40" fill="none" xmlns="http://www.w3.org/2000/svg" style={style}>
    <path
      fill={color}
      d="M 57.54,15.02 C 57.21,14.61 56.77,14.31 56.22,14.11 55.66,13.92 55.14,13.82 54.65,13.82 54.36,13.82 54.06,13.85 53.75,13.92 53.45,13.99 53.16,14.1 52.88,14.24 52.61,14.39 52.39,14.58 52.22,14.82 52.05,15.06 51.96,15.35 51.96,15.69 51.96,16.24 52.16,16.66 52.57,16.95 52.99,17.23 53.5,17.48 54.12,17.69 54.75,17.89 55.41,18.1 56.13,18.3 56.85,18.51 57.52,18.8 58.14,19.18 58.76,19.55 59.28,20.05 59.69,20.69 60.1,21.32 60.3,22.17 60.3,23.23 60.3,24.24 60.11,25.12 59.74,25.87 59.36,26.62 58.86,27.24 58.22,27.74 57.58,28.23 56.84,28.6 55.99,28.84 55.15,29.08 54.26,29.2 53.32,29.2 52.15,29.2 51.05,29.01 50.05,28.66 49.04,28.3 48.1,27.71 47.23,26.91 L 50.07,23.79 C 50.48,24.33 51,24.75 51.62,25.05 52.24,25.35 52.89,25.5 53.55,25.5 53.88,25.5 54.2,25.46 54.54,25.39 54.87,25.31 55.17,25.2 55.43,25.04 55.69,24.89 55.91,24.7 56.07,24.46 56.24,24.22 56.32,23.93 56.32,23.61 56.32,23.06 56.11,22.63 55.69,22.31 55.27,22 54.75,21.73 54.12,21.51 53.49,21.29 52.8,21.06 52.07,20.84 51.34,20.62 50.66,20.32 50.03,19.95 49.4,19.57 48.87,19.07 48.46,18.46 48.04,17.85 47.83,17.04 47.83,16.03 47.83,15.05 48.02,14.2 48.4,13.46 48.79,12.73 49.3,12.11 49.94,11.62 50.58,11.12 51.31,10.75 52.15,10.5 52.99,10.25 53.85,10.13 54.73,10.13 55.76,10.13 56.74,10.28 57.7,10.57 58.65,10.86 59.51,11.35 60.28,12.03 L 57.54,15.02 Z M 57.54,15.02"
    />
    <path
      fill={color}
      d="M 65.29,22.5 C 65.29,23.44 65.55,24.21 66.09,24.79 66.63,25.36 67.38,25.66 68.35,25.66 69.32,25.66 70.08,25.37 70.62,24.79 71.15,24.21 71.42,23.44 71.42,22.5 71.42,21.56 71.15,20.8 70.62,20.22 70.08,19.64 69.32,19.35 68.35,19.35 67.38,19.35 66.63,19.64 66.09,20.22 65.55,20.8 65.29,21.56 65.29,22.5 Z M 61.76,17.6 L 65.28,16.27 65.28,17.91 65.33,17.91 C 65.49,17.69 65.68,17.46 65.93,17.23 66.18,17 66.47,16.8 66.8,16.61 67.14,16.42 67.5,16.27 67.89,16.14 68.29,16.03 68.7,15.96 69.14,15.96 70.06,15.96 70.9,16.12 71.65,16.44 72.4,16.75 73.04,17.2 73.58,17.77 74.12,18.34 74.53,19.02 74.82,19.81 75.11,20.59 75.26,21.45 75.26,22.39 75.26,23.27 75.13,24.1 74.86,24.89 74.59,25.69 74.22,26.39 73.73,27.01 73.25,27.62 72.66,28.11 71.96,28.48 71.26,28.85 70.47,29.04 69.58,29.04 68.78,29.04 68.03,28.91 67.34,28.66 66.65,28.42 66.09,27.99 65.64,27.4 L 65.59,27.4 65.59,34.58 61.76,34.58 61.76,17.6 Z M 61.76,17.6"
    />
    <path
      fill={color}
      d="M 85.48,20.13 C 84.94,19.55 84.19,19.26 83.22,19.26 82.25,19.26 81.49,19.55 80.95,20.13 80.42,20.71 80.15,21.47 80.15,22.41 80.15,23.35 80.42,24.11 80.95,24.69 81.49,25.27 82.25,25.57 83.22,25.57 84.19,25.57 84.94,25.28 85.48,24.69 86.01,24.11 86.28,23.35 86.28,22.41 86.28,21.48 86.01,20.72 85.48,20.13 Z M 89.81,29.13 L 85.98,29.13 85.98,27.31 85.93,27.31 C 85.49,27.91 84.92,28.33 84.23,28.58 83.54,28.83 82.79,28.95 81.99,28.95 81.11,28.95 80.31,28.76 79.61,28.4 78.91,28.03 78.32,27.54 77.83,26.92 77.35,26.31 76.97,25.6 76.71,24.81 76.45,24.01 76.31,23.18 76.31,22.31 76.31,21.37 76.46,20.51 76.75,19.72 77.04,18.93 77.45,18.25 77.99,17.68 78.53,17.11 79.17,16.66 79.92,16.35 80.67,16.03 81.5,15.88 82.43,15.88 82.87,15.88 83.29,15.93 83.68,16.06 84.07,16.17 84.43,16.33 84.76,16.52 85.1,16.71 85.39,16.92 85.64,17.15 85.88,17.38 86.08,17.61 86.24,17.83 L 86.29,17.83 86.29,16.19 89.81,17.46 89.81,29.13 Z M 89.81,29.13"
    />
    <path
      fill={color}
      d="M 118.74,20.09 C 118.21,19.51 117.45,19.21 116.48,19.21 115.51,19.21 114.76,19.5 114.22,20.09 113.68,20.67 113.42,21.43 113.42,22.37 113.42,23.31 113.68,24.07 114.22,24.65 114.76,25.23 115.51,25.52 116.48,25.52 117.45,25.52 118.21,25.23 118.74,24.65 119.28,24.07 119.55,23.31 119.55,22.37 119.55,21.43 119.28,20.67 118.74,20.09 Z M 123.08,29.08 L 119.24,29.08 119.24,27.26 119.19,27.26 C 118.75,27.86 118.18,28.28 117.49,28.53 116.8,28.78 116.06,28.9 115.26,28.9 114.37,28.9 113.58,28.71 112.88,28.35 112.18,27.98 111.59,27.49 111.1,26.87 110.61,26.26 110.24,25.55 109.98,24.76 109.71,23.96 109.58,23.13 109.58,22.26 109.58,21.32 109.72,20.46 110.01,19.67 110.3,18.88 110.72,18.2 111.25,17.63 111.79,17.06 112.43,16.61 113.18,16.3 113.93,15.98 114.77,15.83 115.69,15.83 116.13,15.83 116.55,15.89 116.94,16.01 117.33,16.13 117.7,16.28 118.03,16.47 118.36,16.66 118.65,16.87 118.9,17.1 119.15,17.33 119.35,17.56 119.5,17.78 L 119.55,17.78 119.55,16.14 123.08,17.34 123.08,29.08 Z M 123.08,29.08"
    />
    <path
      fill={color}
      d="M 133.23,20.51 C 133.04,20.24 132.77,20.01 132.43,19.83 132.09,19.65 131.72,19.57 131.33,19.57 130.36,19.57 129.61,19.85 129.07,20.44 128.54,21.02 128.27,21.78 128.27,22.72 128.27,23.66 128.54,24.42 129.07,25 129.61,25.58 130.36,25.88 131.33,25.88 131.76,25.88 132.13,25.78 132.43,25.59 132.74,25.41 133,25.18 133.23,24.93 L 135.76,27.6 C 135.16,28.21 134.46,28.64 133.65,28.89 132.84,29.14 132.07,29.26 131.34,29.26 130.39,29.26 129.48,29.11 128.64,28.8 127.8,28.49 127.07,28.05 126.44,27.48 125.82,26.91 125.33,26.22 124.97,25.41 124.62,24.61 124.44,23.71 124.44,22.72 124.44,21.73 124.62,20.83 124.97,20.03 125.33,19.23 125.82,18.54 126.44,17.96 127.07,17.39 127.8,16.95 128.64,16.64 129.49,16.33 130.39,16.18 131.34,16.18 132.07,16.18 132.84,16.3 133.65,16.55 134.46,16.8 135.16,17.23 135.76,17.84 L 133.23,20.51 Z M 133.23,20.51"
    />
    <path
      fill={color}
      d="M 148.83,28.94 L 145.15,28.94 145.15,27.25 145.1,27.25 C 144.97,27.49 144.79,27.73 144.58,27.97 144.36,28.21 144.1,28.42 143.8,28.61 143.49,28.8 143.14,28.95 142.75,29.08 142.35,29.19 141.93,29.26 141.47,29.26 140.5,29.26 139.71,29.11 139.1,28.81 138.5,28.51 138.03,28.09 137.7,27.56 137.37,27.03 137.15,26.41 137.04,25.69 136.92,24.97 136.87,24.2 136.87,23.36 L 136.87,16.49 140.7,16.49 140.7,22.59 C 140.7,22.95 140.72,23.32 140.74,23.7 140.77,24.09 140.85,24.44 140.97,24.77 141.1,25.09 141.3,25.35 141.56,25.56 141.82,25.77 142.2,25.87 142.7,25.87 143.19,25.87 143.59,25.78 143.9,25.6 144.21,25.42 144.44,25.19 144.6,24.88 144.77,24.59 144.87,24.25 144.92,23.87 144.97,23.49 145,23.1 145,22.69 L 145,16.49 148.83,17.4 148.83,28.94 148.83,28.94 Z M 148.83,28.94"
    />
    <path
      fill={color}
      d="M 158.53,20.15 C 157.97,19.45 157.23,19.1 156.31,19.1 155.98,19.1 155.66,19.18 155.36,19.33 155.05,19.49 154.9,19.76 154.9,20.13 154.9,20.44 155.05,20.66 155.37,20.81 155.69,20.95 156.09,21.08 156.57,21.19 157.06,21.31 157.58,21.43 158.13,21.55 158.68,21.68 159.2,21.88 159.69,22.15 160.17,22.43 160.57,22.8 160.89,23.27 161.2,23.74 161.36,24.37 161.36,25.15 161.36,25.95 161.19,26.62 160.84,27.14 160.49,27.66 160.04,28.08 159.5,28.39 158.95,28.71 158.34,28.93 157.66,29.06 156.97,29.18 156.3,29.25 155.64,29.25 154.77,29.25 153.89,29.13 153.01,28.88 152.12,28.63 151.37,28.19 150.76,27.55 L 153.08,24.97 C 153.44,25.41 153.84,25.75 154.27,25.98 154.7,26.21 155.21,26.32 155.79,26.32 156.23,26.32 156.63,26.26 156.99,26.13 157.35,26.01 157.53,25.77 157.53,25.43 157.53,25.1 157.37,24.86 157.05,24.7 156.74,24.54 156.34,24.4 155.85,24.29 155.37,24.17 154.85,24.06 154.3,23.93 153.74,23.8 153.22,23.61 152.74,23.35 152.25,23.1 151.85,22.73 151.54,22.26 151.22,21.79 151.06,21.16 151.06,20.38 151.06,19.65 151.21,19.01 151.51,18.48 151.81,17.95 152.2,17.52 152.68,17.17 153.17,16.84 153.73,16.58 154.36,16.42 154.99,16.26 155.63,16.17 156.28,16.17 157.1,16.17 157.92,16.29 158.76,16.53 159.59,16.77 160.3,17.2 160.88,17.81 L 158.53,20.15 Z M 158.53,20.15"
    />
    <path
      fill={color}
      d="M 108.78,19.59 L 108.78,16.19 105.3,16.19 105.3,12.58 101.47,12.58 101.47,16.27 101.47,19.35 101.47,25.27 C 101.47,25.96 101.58,26.54 101.81,27.02 102.04,27.5 102.36,27.89 102.77,28.18 103.18,28.48 103.65,28.7 104.2,28.84 104.75,28.97 105.34,29.04 105.99,29.04 106.43,29.04 106.88,29.01 107.34,28.96 107.8,28.91 108.25,28.81 108.67,28.66 L 108.67,25.45 C 108.5,25.6 108.25,25.7 107.92,25.74 107.58,25.79 107.32,25.81 107.11,25.81 106.7,25.81 106.38,25.75 106.13,25.64 105.88,25.53 105.7,25.37 105.58,25.17 105.46,24.96 105.38,24.72 105.35,24.44 105.31,24.16 105.3,23.84 105.3,23.5 L 105.3,19.59 108.78,19.59 108.78,19.59 Z M 108.78,19.59"
    />
    <path
      fill={color}
      d="M 100.24,19.71 C 100.05,19.66 99.68,19.62 99.5,19.61 99.32,19.59 99.14,19.58 98.97,19.58 98.39,19.58 97.91,19.68 97.52,19.89 97.13,20.1 96.82,20.35 96.59,20.65 96.35,20.94 96.18,21.26 96.07,21.61 95.97,21.96 95.92,22.26 95.92,22.52 L 95.92,29.19 91.65,29.19 91.65,16.2 95.76,16.2 95.76,18.08 95.82,18.08 C 96.15,17.41 96.62,16.87 97.22,16.45 97.84,16.03 98.53,15.81 99.33,15.81 99.51,15.81 99.68,15.82 99.84,15.84 100.01,15.86 100.14,15.88 100.25,15.92 L 100.25,19.71 100.24,19.71 Z M 100.24,19.71"
    />
    <path fill={color} d="M 8.91,2.87 L 11.77,0 20.35,8.61 20.36,14.36 8.91,2.87 Z M 8.91,2.87" />
    <path
      fill={color}
      d="M 24.22,0.06 L 28.27,0.06 28.27,12.23 24.22,16.3 24.22,0.06 Z M 24.22,0.06"
    />
    <path
      fill={color}
      d="M 37.03,8.93 L 39.89,11.8 31.31,20.41 25.58,20.41 37.03,8.93 Z M 37.03,8.93"
    />
    <path
      fill={color}
      d="M 39.83,24.29 L 39.83,28.34 27.69,28.34 23.64,24.29 39.83,24.29 Z M 39.83,24.29"
    />
    <path
      fill={color}
      d="M 30.99,37.13 L 28.13,40 19.54,31.39 19.54,25.64 30.99,37.13 Z M 30.99,37.13"
    />
    <path
      fill={color}
      d="M 15.68,39.94 L 11.63,39.94 11.63,27.77 15.68,23.7 15.68,39.94 Z M 15.68,39.94"
    />
    <path fill={color} d="M 2.86,31.07 L 0,28.2 8.59,19.59 14.32,19.59 2.86,31.07 Z M 2.86,31.07" />
    <path
      fill={color}
      d="M 0.06,15.71 L 0.06,11.66 12.2,11.66 16.26,15.71 0.06,15.71 Z M 0.06,15.71"
    />
    <rect fill={color} x="5.62" y="6.05" width="3.05" height="2.5" />
    <rect
      fill={color}
      x="-1.53"
      y="-1.25"
      width="3.05"
      height="2.5"
      transform="translate(19.6, 2.1) rotate(44.95)"
    />
    <rect fill={color} x="30.85" y="5.72" width="2.5" height="3.05" />
    <rect
      fill={color}
      x="-1.25"
      y="-1.53"
      width="2.5"
      height="3.05"
      transform="translate(37.3, 19.7) rotate(44.9)"
    />
    <rect fill={color} x="30.65" y="31" width="3" height="2.5" />
    <rect
      fill={color}
      x="-1.53"
      y="-1.25"
      width="3.05"
      height="2.5"
      transform="translate(19.7, 37.45) rotate(44.95)"
    />
    <rect fill={color} x="6" y="30.78" width="2.5" height="3.05" />
    <rect
      fill={color}
      x="-1.25"
      y="-1.53"
      width="2.5"
      height="3.05"
      transform="translate(2.05, 19.85) rotate(44.9)"
    />
  </svg>
)

const Mark = ({ color = Colors.UI.White, style }: Props): JSX.Element => (
  <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" style={style}>
    <path fill={color} d="M 9.44,-0 L 7.14,2.27 16.33,11.35 16.32,6.81 9.44,-0 Z M 9.44,-0" />
    <path
      fill={color}
      d="M 19.43,0.05 L 19.43,12.89 22.67,9.67 22.67,0.05 19.43,0.05 Z M 19.43,0.05"
    />
    <path
      fill={color}
      d="M 20.52,16.14 L 25.12,16.14 32,9.33 29.71,7.06 20.52,16.14 Z M 20.52,16.14"
    />
    <path
      fill={color}
      d="M 18.96,19.21 L 22.21,22.42 31.95,22.42 31.95,19.21 18.96,19.21 Z M 18.96,19.21"
    />
    <path
      fill={color}
      d="M 15.68,24.83 L 22.56,31.63 24.86,29.37 15.67,20.28 15.68,24.83 Z M 15.68,24.83"
    />
    <path
      fill={color}
      d="M 9.32,21.96 L 9.32,31.59 12.57,31.59 12.57,18.74 9.32,21.96 Z M 9.32,21.96"
    />
    <path fill={color} d="M 6.88,15.5 L 0,22.3 2.29,24.57 11.48,15.49 6.88,15.5 Z M 6.88,15.5" />
    <path fill={color} d="M 0.05,9.22 L 0.05,12.43 13.04,12.43 9.78,9.22 0.05,9.22 Z M 0.05,9.22" />
    <rect fill={color} x="4.53" y="4.78" width="2.45" height="2" />
    <rect
      fill={color}
      x="-1.2"
      y="-1"
      width="2.4"
      height="2"
      transform="translate(15.75, 1.63) rotate(44.7)"
    />
    <rect fill={color} x="24.75" y="4.53" width="2" height="2.4" />
    <rect
      fill={color}
      x="-1"
      y="-1.2"
      width="2"
      height="2.4"
      transform="translate(29.9, 15.58) rotate(44.65)"
    />
    <rect fill={color} x="24.57" y="24.48" width="2.45" height="2" />
    <rect
      fill={color}
      x="-1.2"
      y="-1"
      width="2.4"
      height="2"
      transform="translate(15.8, 29.63) rotate(44.65)"
    />
    <rect fill={color} x="4.8" y="24.33" width="2" height="2.4" />
    <rect
      fill={color}
      x="-1"
      y="-1.2"
      width="2"
      height="2.4"
      transform="translate(1.65, 15.68) rotate(44.7)"
    />
  </svg>
)

const Logo = ({ type = 'lockup', color, style }: Props): JSX.Element => {
  switch (type) {
    case 'mark':
      return <Mark color={color} style={style} />

    default:
      return <Lockup color={color} style={style} />
  }
}

export default Logo
