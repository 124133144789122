import styled from 'styled-components'
import Dimensions from 'spartacus/styles/dimensions'
import Colors from 'spartacus/styles/colors'

export const Container = styled.header`
  position: fixed;
  z-index: 1101;
  width: 100%;
  box-shadow: none;
  border-bottom: none;
  display: flex;
  background: ${Colors.Brand.Primary4};
  height: 80px;

  @media ${Dimensions.md} {
    position: sticky;
    height: 100vh;
    top: 0;
  }
`

export const DesktopNav = styled.div`
  display: none;

  @media ${Dimensions.md} {
    display: block;
  }
`

export const DesktopLogo = styled.div`
  width: 100%;
  height: 72px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 ${Dimensions.nColumns(1 / 3)};
`

export const DesktopNavLinks = styled.div`
  padding: 20px 0;
`

export const DesktopNavLink = styled.div<{ 'data-active': boolean }>`
  a {
    padding: 12px ${Dimensions.nColumns(1 / 3)};
    color: ${Colors.UI.White};
    display: block;
    background: ${(props): string =>
      props['data-active'] ? Colors.Brand.Primary1 : 'transparent'};
    transition: 0.3s background;

    &:hover {
      background: ${(props): string =>
        props['data-active']
          ? Colors.Brand.Primary1
          : Colors.util.fade(Colors.Brand.Primary1, 0.5)};
      color: ${Colors.UI.White};
    }
  }
`

export const MobileNav = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: space-between;
  padding: 0 ${Dimensions.nColumns(1)};

  @media ${Dimensions.md} {
    display: none;
  }
`

export const MobileLeft = styled.div`
  flex: 1;
  display: flex;
`

export const MobileCenter = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
`

export const MobileRight = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-end;
  color: ${Colors.UI.White};
`

export const DrawerContent = styled.div`
  position: relative;
  padding: 60px ${Dimensions.nColumns(2)};

  > a {
    font-family: 'Mabry', sans-serif;
    font-size: 38px;
    color: ${Colors.Brand.Primary5};
    letter-spacing: -0.8px;
    line-height: 52px;
    margin-bottom: 24px;
    display: block;
  }
`

export const DrawerTop = styled.div`
  width: 100vw;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px ${Dimensions.nColumns(1)};
  color: ${Colors.UI.White};
`
