import styled from 'styled-components'
import { makeStyles, createStyles } from '@material-ui/core/styles'
import Dimensions from 'spartacus/styles/dimensions'
import Colors from 'spartacus/styles/colors'
import { VariantProp as Props } from '.'

interface Classes {
  [key: string]: string
}

const baseAppBarStyles = createStyles({
  root: {
    // TODO: these are temporary to match previous design
    // boxShadow: 'none',
    boxShadow: '0 1px 4px 0 rgba(0,0,0,0.04)',
    borderBottom: '0.5px solid rgba(31,31,31,0.15)',
  },
})

const useTransparentIconButtonClasses = makeStyles({
  root: {
    color: Colors.UI.White,
  },
})

const useDefaultIconButtonClasses = makeStyles({
  root: {
    color: Colors.Brand.Primary1,
  },
})

const useLightAppBarStyles = makeStyles({
  root: {
    ...baseAppBarStyles.root,
    backgroundColor: Colors.UI.White,
  },
})

const useTransparentAppBarStyles = makeStyles({
  root: {
    boxShadow: 'none',
    borderBottom: 'none',
    backgroundColor: 'transparent',
  },
})

const useDarkAppBarStyles = makeStyles({
  root: {
    ...baseAppBarStyles.root,
    backgroundColor: Colors.Brand.Primary1,
  },
})

const baseToolbarStyles = createStyles({
  root: {
    minHeight: 80,
    padding: `0 ${Dimensions.nColumns(1)}`,
  },
})

const useLightToolbarStyles = makeStyles({
  root: {
    ...baseToolbarStyles.root,
    color: Colors.Brand.Primary1,
  },
})

const useDarkToolbarStyles = makeStyles({
  root: {
    ...baseToolbarStyles.root,
    color: Colors.UI.White,
  },
})

const useLightDrawerStyles = makeStyles({
  root: {
    '& .MuiDrawer-paper': {
      width: '100%',
      backgroundColor: Colors.UI.White,
      color: Colors.Brand.Primary1,
    },
  },
})

const useDarkDrawerStyles = makeStyles({
  root: {
    '& .MuiDrawer-paper': {
      width: '100%',
      backgroundColor: Colors.Brand.Primary1,
      color: Colors.UI.White,
    },
  },
})

export const getAppBarClasses = (variant: Props['variant']): Classes => {
  const lightAppBarClasses = useLightAppBarStyles()
  const darkAppBarClasses = useDarkAppBarStyles()
  const transparentAppBarStyles = useTransparentAppBarStyles()

  if (variant === 'dark') return darkAppBarClasses
  if (variant === 'transparent') return transparentAppBarStyles

  return lightAppBarClasses
}

export const getToolbarClasses = (variant: Props['variant']): Classes => {
  const lightToolbarClasses = useLightToolbarStyles()
  const darkToolbarClasses = useDarkToolbarStyles()

  if (variant === 'dark') return darkToolbarClasses

  return lightToolbarClasses
}

export const getIconButtonClasses = (variant: Props['variant']): Classes => {
  const transparentIconButtonClasses = useTransparentIconButtonClasses()
  const defaultIconButtonClasses = useDefaultIconButtonClasses()

  if (variant === 'transparent') return transparentIconButtonClasses

  return defaultIconButtonClasses
}

export const getDrawerClasses = (variant: Props['variant']): Classes => {
  const lightDrawerClasses = useLightDrawerStyles()
  const darkDrawerClasses = useDarkDrawerStyles()

  if (variant === 'dark') return darkDrawerClasses

  return lightDrawerClasses
}

export const Spacer = styled.div`
  flex: 1;
`

export const DesktopNavigation = styled.div<{ linkColor?: string; linkHoverColor?: string }>`
  display: flex;
  align-items: center;
  justify-content: center;

  a:first-child {
    text-decoration: underline;

    &:hover {
      text-decoration: none;
      color: ${({ linkColor = 'inherit' }): string => linkColor};
    }
  }

  a:nth-child(2) {
    &:hover {
      color: ${({ linkHoverColor = 'inherit' }): string => linkHoverColor};
    }
  }

  @media ${Dimensions.md} {
    a:first-child {
      display: block;
      margin-right: ${Dimensions.nColumns(0.5)};

      &:last-child {
        margin-right: 0;
      }
    }
  }
`

export const MobileNavigationDrawerContent = styled.div`
  padding: 4vh ${Dimensions.nColumns(2)};

  a {
    display: block;
    color: inherit;
    margin-bottom: 2vh;
  }
`
