import React, { useState } from 'react'
import { observer } from 'mobx-react'
import { AppBar as MuiAppBar, Toolbar as MuiToolbar } from '@material-ui/core'
import useStores from 'spartacus/hooks/useStores'
import useScroll from 'spartacus/hooks/useScroll'
import useBreakpoint from 'spartacus/hooks/useBreakpoint'
import Logo from 'spartacus/components/Header/Logo'
import * as S from './style'
import DesktopNavigation from './DesktopNavigation'
import MobileNavigation from './MobileNavigation'

export type VariantProp = {
  variant?: 'light' | 'dark' | 'transparent'
}
export interface Props extends VariantProp {
  pathName?: string
}

const Header = observer(({ variant = 'light', pathName }: Props): null | JSX.Element => {
  const [desktopMenuAnchorElement, setDesktopMenuAnchorElement] = useState<null | HTMLElement>(null)
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState<boolean>(false)
  const { sessionStore, analyticsStore } = useStores()
  const { scrollY } = useScroll()
  const isAtLeastMiddleSize = useBreakpoint('md')
  const newVariant =
    pathName === '/' && scrollY === 0 && isMobileMenuOpen === false ? 'transparent' : variant
  const appBarClasses = S.getAppBarClasses(newVariant)
  const toolbarClasses = S.getToolbarClasses(newVariant)

  return (
    <MuiAppBar position="sticky" classes={appBarClasses}>
      <MuiToolbar disableGutters classes={toolbarClasses}>
        <Logo
          user={sessionStore.user}
          variant={newVariant}
          analyticsStore={analyticsStore}
          setDesktopMenuAnchorElement={setDesktopMenuAnchorElement}
        />
        <S.Spacer />
        {!isAtLeastMiddleSize && (
          <MobileNavigation
            isMobileMenuOpen={isMobileMenuOpen}
            setIsMobileMenuOpen={setIsMobileMenuOpen}
            sessionStore={sessionStore}
            analyticsStore={analyticsStore}
            setDesktopMenuAnchorElement={setDesktopMenuAnchorElement}
            variant={newVariant}
          />
        )}
        {isAtLeastMiddleSize && (
          <DesktopNavigation
            sessionStore={sessionStore}
            analyticsStore={analyticsStore}
            variant={newVariant}
            desktopMenuAnchorElement={desktopMenuAnchorElement}
            setDesktopMenuAnchorElement={setDesktopMenuAnchorElement}
          />
        )}
      </MuiToolbar>
    </MuiAppBar>
  )
})

export default Header
