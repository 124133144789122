import styled from 'styled-components'
import dimensions from 'spartacus/styles/dimensions'
import Colors from 'spartacus/styles/colors'
import Shadows from 'spartacus/styles/shadows'

export const Container = styled.section`
  margin-bottom: -15px;
`

export const Content = styled.main`
  flex: 1 0 auto;
`

export const AncillaryContent = styled.div`
  background: ${Colors.UI.White};
  border-radius: 12px;
  box-shadow: ${Shadows.Shadow1};
  padding: 4vh ${dimensions.nColumns(2)};
  margin: 2vh ${dimensions.nColumns(1)};

  @media ${dimensions.lg} {
    margin: 4vh ${dimensions.nColumns(4)};
  }

  @media ${dimensions.xxl} {
    margin: 4vh ${dimensions.nColumns(6)};
  }
`

export const DashboardLayout = styled.section`
  background: ${Colors.Brand.Primary5};
  display: grid;
  min-height: 100vh;
  grid-template-areas:
    'primary-nav'
    'content'
    'footer';

  grid-template-rows: auto 1fr auto;

  @media ${dimensions.md} {
    grid-template-areas:
      'primary-nav secondary-nav'
      'primary-nav content'
      'primary-nav footer';

    grid-template-rows: auto 1fr auto;
    grid-template-columns: ${dimensions.nColumns(4)} 1fr;
  }

  @media ${dimensions.lg} {
    grid-template-columns: ${dimensions.nColumns(3)} 1fr;
  }
`

export const DashboardPrimaryNav = styled.div`
  grid-area: primary-nav;
  position: relative;
`

export const DashboardSecondaryNav = styled.div`
  display: none;

  @media ${dimensions.md} {
    display: block;
    grid-area: secondary-nav;
  }
`

export const DashboardContent = styled.div`
  grid-area: content;
  padding-top: 80px;

  @media ${dimensions.md} {
    padding-top: 0;
  }
`

export const DashboardFooter = styled.div`
  grid-area: footer;
  padding: ${dimensions.nColumns(6)} ${dimensions.nColumns(1)} ${dimensions.nColumns(1)}
    ${dimensions.nColumns(1)};
  text-align: center;

  @media ${dimensions.md} {
    padding: ${dimensions.nColumns(2)} ${dimensions.nColumns(1)} ${dimensions.nColumns(0.5)}
      ${dimensions.nColumns(1)};
    text-align: left;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: flex-end;
  }
`

export const FooterLinks = styled.div`
  a {
    margin: 0 8px;
  }

  @media ${dimensions.md} {
    margin-left: ${dimensions.nColumns(3)};
    display: flex;
    align-items: baseline;
  }
`
