import { useMediaQuery } from '@material-ui/core'
import { Breakpoint } from 'spartacus/constants'
import Dimensions from 'spartacus/styles/dimensions'

/**
 * returns true if the breakpoint conditions are met; false if not
 * @param size Breakpoint
 */
export default function useBreakpoint(size: Breakpoint): boolean {
  return useMediaQuery(Dimensions[size])
}
