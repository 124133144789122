import React from 'react'
import Link from 'next/link'
import useStores from 'spartacus/hooks/useStores'
import Logo from 'spartacus/components/Logo'
import Typography from 'spartacus/components/Typography'
import Colors from 'spartacus/styles/colors'
import * as S from './style'

interface LinkProps {
  section: 'company' | 'social' | 'contact'
  label: string
  analyticsLabel: string
  href: string
}

const InternalLink = ({ section, label, analyticsLabel, href }: LinkProps): JSX.Element => {
  const { analyticsStore } = useStores()

  return (
    <Link href={href} passHref>
      <Typography
        element="a"
        kind="TS2 - Regular"
        color={Colors.Brand.Primary5}
        onClick={(): void => {
          analyticsStore.event({
            category: 'footer',
            action: section,
            label: analyticsLabel,
          })
        }}
      >
        {label}
      </Typography>
    </Link>
  )
}

const ExternalLink = ({ section, label, analyticsLabel, href }: LinkProps): JSX.Element => {
  const { analyticsStore } = useStores()

  return (
    <Typography
      element="a"
      href={href}
      kind="TS2 - Regular"
      color={Colors.Brand.Primary5}
      onClick={(): void => {
        analyticsStore.event({
          category: 'footer',
          action: section,
          label: analyticsLabel,
        })
      }}
    >
      {label}
    </Typography>
  )
}

const Footer = (): JSX.Element => (
  <S.Container>
    <S.Sections>
      <S.Section>
        <Typography element="p" kind="TS3 - Strong" color={Colors.Brand.Primary5}>
          Company
        </Typography>
        <S.List>
          <S.ListItem>
            <InternalLink section="company" label="Home" analyticsLabel="home" href="/" />
          </S.ListItem>
          <S.ListItem>
            <InternalLink
              section="company"
              label="Privacy"
              analyticsLabel="privacy"
              href="/privacy-policy"
            />
          </S.ListItem>
          <S.ListItem>
            <InternalLink
              section="company"
              label="Terms & Conditions"
              analyticsLabel="terms and conditions"
              href="/terms-and-conditions"
            />
          </S.ListItem>
          <S.ListItem>
            <InternalLink
              section="company"
              label="Frequently Asked Questions"
              analyticsLabel="faq"
              href="/faq"
            />
          </S.ListItem>
          <S.ListItem>
            <InternalLink section="company" label="Jobs" analyticsLabel="jobs" href="/jobs" />
          </S.ListItem>
        </S.List>
      </S.Section>

      <S.Section>
        <Typography element="p" kind="TS3 - Strong" color={Colors.Brand.Primary5}>
          Social
        </Typography>
        <S.List>
          <S.ListItem>
            <ExternalLink
              section="social"
              label="Twitter"
              analyticsLabel="twitter"
              href="https://twitter.com/getspartacus"
            />
          </S.ListItem>
          <S.ListItem>
            <ExternalLink
              section="social"
              label="Facebook"
              analyticsLabel="facebook"
              href="http://facebook.com/getspartacus"
            />
          </S.ListItem>
          <S.ListItem>
            <ExternalLink
              section="social"
              label="Instagram"
              analyticsLabel="instagram"
              href="https://www.instagram.com/getspartacus"
            />
          </S.ListItem>
          <S.ListItem>
            <ExternalLink
              section="social"
              label="White Paper"
              analyticsLabel="white paper"
              href="https://cdn.spartacus.com/white-papers/fiduciarydatabanking.3.7.2019.pdf"
            />
          </S.ListItem>
        </S.List>
      </S.Section>

      <S.Section>
        <Typography element="p" kind="TS3 - Strong" color={Colors.Brand.Primary5}>
          Contact
        </Typography>
        <S.List>
          <S.ListItem>
            <ExternalLink
              section="contact"
              label="Email"
              analyticsLabel="email"
              href="mailto:support@spartacus.com"
            />
          </S.ListItem>
        </S.List>
      </S.Section>
    </S.Sections>
    <S.LogoContainer>
      <Logo color={Colors.Brand.Primary5} type="mark" />
    </S.LogoContainer>
  </S.Container>
)

export default Footer
