import React from 'react'
import Link from 'next/link'
import { useRouter } from 'next/router'
import {
  IconButton as MuiIconButton,
  Menu as MuiMenu,
  MenuItem as MuiMenuItem,
} from '@material-ui/core'
import { AccountCircle as AccountIcon } from '@material-ui/icons'
import Typography from 'spartacus/components/Typography'
import Button from 'spartacus/components/Button'
import Colors from 'spartacus/styles/colors'
import SessionStore from 'spartacus/stores/SessionStore'
import AnalyticsStore from 'spartacus/stores/AnalyticsStore'
import { matchLocationPathnames } from 'spartacus/utils'
import * as S from './style'
import { VariantProp } from '.'

interface Props extends VariantProp {
  sessionStore: SessionStore
  analyticsStore: AnalyticsStore
  desktopMenuAnchorElement: HTMLElement | null
  setDesktopMenuAnchorElement: React.Dispatch<React.SetStateAction<HTMLElement | null>>
}

const DesktopNavigation = ({
  sessionStore,
  analyticsStore,
  variant,
  desktopMenuAnchorElement,
  setDesktopMenuAnchorElement,
}: Props): null | JSX.Element => {
  const router = useRouter()
  const isDesktopMenuOpen = Boolean(desktopMenuAnchorElement)
  const iconButtonClasses = S.getIconButtonClasses(variant)

  const handleDesktopMenuClick = (event: React.MouseEvent<HTMLElement>): void => {
    setDesktopMenuAnchorElement(event.currentTarget)
  }

  const handleDesktopMenuClose = (): void => {
    setDesktopMenuAnchorElement(null)
  }

  if (sessionStore.isAuthorizing) {
    return null
  }

  if (sessionStore.user) {
    return (
      <S.DesktopNavigation
        linkColor={variant === 'transparent' ? Colors.UI.White : Colors.Type.Primary}
        linkHoverColor={variant === 'dark' ? Colors.Type.Primary : Colors.UI.White}
      >
        <Link href="/dashboard" passHref>
          <Typography
            element="a"
            kind="TS1 - Regular"
            color={variant === 'transparent' ? Colors.UI.White : Colors.Type.Primary}
            onClick={(): void => {
              analyticsStore.event({
                category: 'nav',
                action: 'page',
                label: 'dashboard',
              })
            }}
          >
            Dashboard
          </Typography>
        </Link>
        <MuiIconButton
          aria-label="account of current user"
          aria-controls="menu-app-bar"
          aria-haspopup="true"
          onClick={handleDesktopMenuClick}
          edge="end"
        >
          <AccountIcon classes={iconButtonClasses} />
        </MuiIconButton>
        <MuiMenu
          id="menu-app-bar"
          anchorEl={desktopMenuAnchorElement}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          keepMounted
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={isDesktopMenuOpen}
          onClose={handleDesktopMenuClose}
        >
          {sessionStore.abilities.can('read', 'Account') && (
            <MuiMenuItem
              onClick={(): void => {
                handleDesktopMenuClose()
                router.push('/account')
              }}
            >
              Account Settings
            </MuiMenuItem>
          )}

          <MuiMenuItem
            onClick={(): void => {
              handleDesktopMenuClose()
              analyticsStore.event({
                category: 'account',
                action: 'nav',
                label: 'log out',
              })
              router.push('/logged-out')
            }}
          >
            Log Out
          </MuiMenuItem>
        </MuiMenu>
      </S.DesktopNavigation>
    )
  }

  // Don't show "Get started" button leading to /offer during the purchase, it can confuse the user
  const showGetStartedOffer = !matchLocationPathnames([
    '/checkout',
    '/onboarding',
    '/purchase-confirmation',
  ])

  return (
    <S.DesktopNavigation
      linkColor={variant === 'transparent' ? Colors.UI.White : Colors.Type.Primary}
      linkHoverColor={variant === 'dark' ? Colors.Type.Primary : Colors.UI.White}
    >
      <Link href="/log-in">
        <Typography
          style={{ marginRight: '48px' }}
          color={variant === 'transparent' ? Colors.UI.White : Colors.Type.Primary}
          element="a"
          kind="TSB - Button"
        >
          Log In
        </Typography>
      </Link>
      {showGetStartedOffer && (
        <Link href="/offer" passHref>
          <Button
            variant={variant === 'dark' ? 'primary-light' : 'primary'}
            width="auto"
            onClick={(): void => {
              analyticsStore.event({
                category: 'nav',
                action: 'page',
                label: 'get started',
              })
            }}
          >
            Get Started
          </Button>
        </Link>
      )}
    </S.DesktopNavigation>
  )
}

export default DesktopNavigation
