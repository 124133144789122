import styled from 'styled-components'
import dimensions from 'spartacus/styles/dimensions'
import Colors from 'spartacus/styles/colors'

export const Container = styled.div`
  height: 72px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0 ${dimensions.nColumns(1)};

  > * {
    margin-right: ${dimensions.nColumns(1)};

    &:last-child {
      margin-right: 0;
    }
  }

  a {
    color: ${Colors.Brand.Primary1};
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }
`

export const User = styled.div`
  a {
    color: ${Colors.Type.Secondary};
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;

    svg {
      color: ${Colors.Brand.Primary1};
      margin-right: 8px;
    }
  }
`
