import React from 'react'
import { observer } from 'mobx-react'
import Link from 'next/link'
import { AccountCircleRounded as AccountIcon } from '@material-ui/icons'
import useStores from 'spartacus/hooks/useStores'
import Typography from 'spartacus/components/Typography'
import * as S from './style'

const DashboardSecondaryNav = observer((): JSX.Element | null => {
  const { sessionStore, analyticsStore } = useStores()

  if (!sessionStore.user) {
    return null
  }

  let name = sessionStore.user.firstName

  if (!name) {
    if (sessionStore.user.fullName) {
      ;[name] = sessionStore.user.fullName.split(' ')
    } else {
      name = 'Account'
    }
  }

  return (
    <S.Container>
      {sessionStore.abilities.can('read', 'Account') && (
        <S.User>
          <Link href="/account">
            <a>
              <AccountIcon color="inherit" />
              <Typography element="span" kind="TS1 - Regular">
                {name}
              </Typography>
            </a>
          </Link>
        </S.User>
      )}

      <Link href="/logged-out" passHref>
        <Typography
          element="a"
          kind="TS1 - Regular"
          onClick={(): void => {
            analyticsStore.event({
              category: 'account',
              action: 'nav',
              label: 'log out',
            })
          }}
        >
          Log Out
        </Typography>
      </Link>
    </S.Container>
  )
})

export default DashboardSecondaryNav
